import React from "react";
import Helmet from "react-helmet";

const browser = typeof window !== "undefined" && window;

const NotFoundPage = () => {
  return (
    browser && (
      <div>
        <Helmet title="404 Page not found" />
        <h1>404 page not found</h1>
      </div>
    )
  );
};

export default NotFoundPage;
